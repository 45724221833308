/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import LocalStorageService from '../services/localstorageservice';
import type {
  LoginRequestModel,
  DeviceModel,
  ResetPasswordModel,
  UserRegisterModel,
  ChangePasswordModel,
  MobileVerification,
  AddressModel,
  CompanyModel,
} from '../flow/flowTypes';
import { formatUsername } from '../utils/utils';
import { TacSendType, TacUsageType } from '../utils/enum';

const baseUrl = '/customer/v1/account';
const localStorageService = LocalStorageService.getService();

const login = async (loginModel: LoginRequestModel, grantType: string) => {
  const data = new URLSearchParams();
  data.append('username', loginModel.username);
  data.append('password', loginModel.password);
  data.append('scope', 'openid offline_access');
  data.append('grant_type', grantType);
  data.append('registration', JSON.stringify({ device: { ...loginModel.device } }));
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/login`, data.toString(), config);
    localStorageService.setUser(output);
    localStorageService.setToken(output);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const registerDevice = (deviceModel: DeviceModel) => {
  axiosApiInstance.post(`${baseUrl}/registerdevice`, deviceModel);
};

const getResetToken = async (username: string) => {
  const model = {
    username: formatUsername(username),
  };

  try {
    const output = await axiosApiInstance.post(`${baseUrl}/forgotpasswordtac`, model);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const resetPassword = async (model: ResetPasswordModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/resetpassword`, model);
    return output;
  } catch (e) {
    if (e.response.data.errors) {
      const keys = Object.keys(e.response.data.errors);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const message = e.response.data.errors[key][0];
        toast.error(message);
        break;
      }
    } else {
      toast.error(e.response.data.errorMessage);
    }
    throw e;
  }
};

const getTacCode = async (username: string, type: TacSendType, usage: TacUsageType) => {
  const model = {
    mobileNumber: username,
  };
  try {
    const output = await axiosApiInstance.post(
      `${baseUrl}/taccode?type=${type}&usage=${usage}`,
      model
    );
    return output;
  } catch (e) {
    if (e.response.data.errors) {
      const keys = Object.keys(e.response.data.errors);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const message = e.response.data.errors[key][0];
        toast.error(message);
        break;
      }
    } else {
      toast.error(e.response.data.errorMessage);
    }
    throw e;
  }
};

const registerAccount = async (userModel: UserRegisterModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/Register`, userModel);
    toast.success('Registration success. Please proceed to login');
    return output;
  } catch (e) {
    if (e.response.data.errors) {
      const keys = Object.keys(e.response.data.errors);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const message = e.response.data.errors[key][0];
        toast.error(message);
        break;
      }
    } else {
      toast.error(e.response.data.errorMessage);
    }
    throw e;
  }
};

const changePassword = async (model: ChangePasswordModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/ChangePassword`, model);
    toast.success('Password has been changed.');
    return output;
  } catch (e) {
    if (e.response.data.errors) {
      const keys = Object.keys(e.response.data.errors);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const message = e.response.data.errors[key][0];
        toast.error(message);
        break;
      }
    } else {
      toast.error(e.response.data.errorMessage);
    }
    throw e;
  }
};

const logout = async () => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/logout`);
    localStorageService.clearData();
    return output;
  } catch (e) {
    localStorageService.clearData();
    throw e;
  }
};

const verifyTacCode = async (model: MobileVerification) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/verifytac`, model);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const viewProfile = async () => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/profile`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const uploadProfilePhoto = async (profileImage: string) => {
  try {
    const formData = new FormData();
    formData.append('file', profileImage);
    const output = await axiosApiInstance.post(`${baseUrl}/UploadProfileImage`, formData);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editProfile = async (userModel: UserRegisterModel, profileImage: string) => {
  try {
    let photo = null;
    if (profileImage) {
      photo = await uploadProfilePhoto(profileImage);
    }
    const output = await axiosApiInstance.put(`${baseUrl}/profile`, userModel);
    toast.success('Update profile success');
    if (photo) {
      output.profilePhoto = photo;
    }
    localStorageService.setUser(output);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editAddress = async (addressId: number, model: AddressModel) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/EditAddress?addressId=${addressId}`,
      model
    );
    const user = localStorageService.getUser();
    const selectedPosition = user.addresses
      ? user.addresses.findIndex((e) => e.id === addressId)
      : -1;
    if (selectedPosition !== -1) {
      user.addresses = [
        ...user.addresses.slice(0, selectedPosition),
        output,
        ...user.addresses.slice(selectedPosition + 1),
      ];
    } else if (user.addresses) {
      user.addresses.push(output);
    } else {
      user.addresses = [output];
    }
    localStorageService.setUser(user);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editCompany = async (companyId: number, model: CompanyModel) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/EditCompany?companyId=${companyId}`,
      model
    );
    const user = localStorageService.getUser();
    const selectedPosition = user.companies
      ? user.companies.findIndex((e) => e.id === companyId)
      : -1;
    if (selectedPosition !== -1) {
      user.companies = [
        ...user.companies.slice(0, selectedPosition),
        output,
        ...user.companies.slice(selectedPosition + 1),
      ];
    } else if (user.companies) {
      user.companies.push(output);
    } else {
      user.companies = [output];
    }
    localStorageService.setUser(user);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const addAddress = async (model: AddressModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/AddAddress`, model);
    const user = localStorageService.getUser();
    if (user.addresses) {
      user.addresses.push(output);
    } else {
      user.addresses = [output];
    }
    localStorageService.setUser(user);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getPostcodes = async (postcode: string) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/postcodes?postcode=${postcode}`);

    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export {
  login,
  registerDevice,
  getResetToken,
  resetPassword,
  getTacCode,
  registerAccount,
  changePassword,
  logout,
  verifyTacCode,
  viewProfile,
  editProfile,
  editAddress,
  editCompany,
  addAddress,
  getPostcodes,
};
