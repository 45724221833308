// @flow
/* eslint-disable import/prefer-default-export */
import LocalStorageService from '../services/localstorageservice';
import type { AuthModel } from '../flow/flowTypes';

const localStorageService = LocalStorageService.getService();

export const checkUser = (authUser: AuthModel) => {
  if (authUser) {
    return true;
  }
  return false;
};

export const initDevice = () => {
  const deviceId = localStorageService.getDeviceId();
  if (!deviceId) {
    localStorageService.setDeviceId(Math.random().toString(36).substr(2));
  }
  return localStorageService.getDeviceId();
};

export const getDeviceId = () => {
  const deviceId = localStorageService.getDeviceId();
  if (!deviceId) {
    return initDevice();
  }
  return deviceId;
};

export const validateEmail = (input: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(input).toLowerCase());
};

export const validateNumber = (input: string) => {
  return /^\d+$/.test(input);
};

export const formatUsername = (input: string) => {
  if (validateNumber) {
    return `0${input}`;
  }
  return input;
};

export const hasUppercase = (input: string) => {
  return input.match(/[A-Z]/);
};

export const hasNumber = (input: string) => {
  return /\d/g.test(input);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const reg = /^01\d{8,9}$/;
  const tempPhone = phoneNumber.trim();
  return reg.test(tempPhone);
};
