/* eslint-disable */
import React from 'react';

import ProfileProvider, { ProfileConsumer } from './ProfileContext/profile';
import TransactionProvider, { TransactionConsumer } from './TransactionContext/transaction';
import MemberProvider, { MemberConsumer } from './MemberContext/member';

const CombinedContextProvider = ({ children }) => (
  <ProfileProvider>
    <TransactionProvider>
      <MemberProvider>{children}</MemberProvider>
    </TransactionProvider>
  </ProfileProvider>
);

const CombineContextConsumer = ({ children, props }) => {
  return (
    <ProfileConsumer>
      {(profileContext) => {
        return (
          <TransactionConsumer>
            {(transactionContext) => {
              return (
                <MemberConsumer>
                  {(memberContext) => {
                    return children({
                      ...profileContext,
                      ...transactionContext,
                      ...memberContext,
                      ...props,
                    });
                  }}
                </MemberConsumer>
              );
            }}
          </TransactionConsumer>
        );
      }}
    </ProfileConsumer>
  );
};

export { CombinedContextProvider, CombineContextConsumer };
