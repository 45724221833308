export const TacSendType = {
  Mobile: 1,
  Postman: 2,
};

export const TacUsageType = {
  FirstTimeRegistration: 1,
  ChangePhone: 2,
  ForgotPasswordMobile: 3,
  OAuthRegister: 4,
};

export const TransactionActionType = {
  Add: 1,
  Minus: 2,
  Promote: 3,
};

export const AddressType = {
  Company: 1,
  Individual: 2,
};

export const ProfileActionType = {
  ViewProfile: 1,
  ViewAddress: 2,
  ViewCompany: 3,
};

export const AccountType = {
  Company: 1,
  Individual: 2,
};
