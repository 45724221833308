/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';

const baseUrl = '/customer/v1/membership';

const getPoint = async () => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/point`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getTier = async () => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/tier`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export { getPoint, getTier };
