/* eslint-disable */
// @flow
import { SHOP_NAME } from '../utils/constants';
import type { AuthModel, MemberPointModel, MemberTierModel } from '../flow/flowTypes';
import themes from '../utils/themeconstants';

const LocalStorageService = (function () {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setUser(authInfo: AuthModel) {
    if (authInfo) {
      localStorage.setItem('auth_user', JSON.stringify(authInfo));
    } else {
      localStorage.setItem('auth_user', '');
    }
  }

  function getUser() {
    if (localStorage.getItem('auth_user')) {
      return JSON.parse(localStorage.getItem('auth_user'));
    }
    return null;
  }

  function setToken(authInfo: AuthModel) {
    localStorage.setItem('access_token', authInfo.auth_token);
    localStorage.setItem('refresh_token', authInfo.refresh_token);
  }

  function setAccessToken(accessToken: string) {
    localStorage.setItem('access_token', accessToken);
  }

  function getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function setRefreshToken(refreshToken: string) {
    localStorage.setItem('refresh_token', refreshToken);
  }

  function getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  function clearData() {
    localStorage.removeItem('auth_user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('my_point');
    localStorage.removeItem('my_tier');
  }

  function setShopName(shopName: string) {
    localStorage.setItem(SHOP_NAME, shopName);
  }

  function getShopName() {
    return localStorage.getItem(SHOP_NAME);
  }

  function setDeviceId(deviceId: string) {
    localStorage.setItem('device_id', deviceId);
  }

  function getDeviceId() {
    return localStorage.getItem('device_id');
  }

  function setRegistrationId(deviceId: string) {
    localStorage.setItem('registration_id', deviceId);
  }

  function getRegistrationId() {
    return localStorage.getItem('registration_id');
  }

  function setTheme(theme: Object) {
    localStorage.setItem('theme', JSON.stringify(theme));
  }

  function getTheme() {
    return localStorage.getItem('theme') || JSON.stringify(themes.default);
  }

  function setPoint(point: MemberPointModel) {
    if (point) {
      localStorage.setItem('my_point', JSON.stringify(point));
    } else {
      localStorage.setItem('my_point', '');
    }
  }

  function getPoint() {
    return localStorage.getItem('my_point') ? JSON.parse(localStorage.getItem('my_point')) : null;
  }

  function setTier(tier: MemberTierModel) {
    if (tier) {
      localStorage.setItem('my_tier', JSON.stringify(tier));
    } else {
      localStorage.setItem('my_tier', '');
    }
  }

  function getTier() {
    return localStorage.getItem('my_tier') ? JSON.parse(localStorage.getItem('my_tier')) : null;
  }

  function setPostcodeInfo(postcodeInfo: MemberTierModel) {
    if (postcodeInfo) {
      localStorage.setItem('postcode_info', JSON.stringify(tier));
    } else {
      localStorage.setItem('postcode_info', '');
    }
  }

  function getPostcodeInfo() {
    return localStorage.getItem('postcode_info')
      ? JSON.parse(localStorage.getItem('postcode_info'))
      : null;
  }

  return {
    getService,
    setUser,
    getUser,
    setToken,
    setAccessToken,
    getAccessToken,
    setRefreshToken,
    getRefreshToken,
    clearData,
    setShopName,
    getShopName,
    setDeviceId,
    getDeviceId,
    setRegistrationId,
    getRegistrationId,
    setTheme,
    getTheme,
    setPoint,
    getPoint,
    setTier,
    getTier,
    setPostcodeInfo,
    getPostcodeInfo,
  };
})();

export default LocalStorageService;
