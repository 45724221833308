/* eslint-disable no-param-reassign, consistent-return */
// @flow
import axios from 'axios';
import { toast } from 'react-toastify';

import LocalStorageService from './localstorageservice';
import { BASE_URL } from '../utils/constants';

const localStorageService = LocalStorageService.getService();

axios.defaults.baseURL = BASE_URL;
let refreshError = false;

const axiosApiInstance = axios.create();

const parseBody = (response) => {
  if (response.status === 200) {
    return response.data;
  }
  return this.parseError(response.data.messages);
};

axiosApiInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      tenant: localStorageService.getShopName(),
      'Content-Type': config.url.includes('login')
        ? 'application/x-www-form-urlencoded'
        : 'application/json',
    };
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: config.url.includes('refresh')
          ? `Bearer ${localStorageService.getRefreshToken()}`
          : `Bearer ${token}`,
        Accept: 'application/json',
      };
    }
    refreshError = false;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string = '') => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    return parseBody(response);
  },
  (error) => {
    console.log('e', error);
    if (!error.response) {
      toast.error('Server error. Please try again later');
      return;
    }
    const originalRequest = error.config;
    if (
      originalRequest &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((authToken) => {
            originalRequest.headers.Authorization = `Bearer ${authToken}`;
            return axiosApiInstance.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const config = {
        headers: {
          tenant: localStorageService.getShopName(),
          'Content-Type': 'application / json',
          Authorization: `Bearer ${localStorageService.getRefreshToken()}`,
          Accept: 'application/json',
        },
      };

      return new Promise((resolve, reject) => {
        axios
          .post('/customer/v1/account/refresh', null, config)
          .then((result) => {
            localStorageService.setAccessToken(result.data.auth_token);
            localStorageService.setRefreshToken(result.data.refresh_token);
            originalRequest.headers.Authorization = `Bearer ${result.data.auth_token}`;
            processQueue(null, result.data.authToken);
            resolve(axiosApiInstance.request(originalRequest));
          })
          .catch((e) => {
            if (!refreshError) {
              toast.error('Unauthorised. Please login to continue');
            }
            refreshError = true;
            processQueue(e);
            reject(e);
            localStorageService.clearData();
            let replaceUrl = '';
            if (window.location.hash.split('/').length >= 2) {
              window.location.hash = `#/member/${localStorageService.getShopName()}/login`;
              replaceUrl = window.location.hash;
            } else {
              replaceUrl = `#${window.location.hash.replace(/^#/, '')}/login`;
            }
            window.location.replace(replaceUrl, null);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
