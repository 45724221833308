/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';

const baseUrl = '/customer/v1/transaction';

const getTransactions = async (skip: number, take: number) => {
  try {
    const output = await axiosApiInstance.get(
      `${baseUrl}/TransactionList?skip=${skip}&take=${take}`
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export { getTransactions };
