/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import type { TransactionPaginationModel } from '../../flow/flowTypes';
import { getTransactions } from '../../controllers/transactionController';

const { Provider, Consumer } = React.createContext<any>();

type State = {
  getMemberTransactions: Function,
  isTransactionLoading: boolean,
  transactionPaginationModel: TransactionPaginationModel,
};

type Props = {
  children: Object,
};

export default class TransactionProvider extends React.Component<Props, State> {
  state = {
    isTransactionLoading: false,
    transactionPaginationModel: null,
    getMemberTransactions: async (skip: number, take: number) => {
      this.setState({ isTransactionLoading: true });
      try {
        const result = await getTransactions(skip, take);
        this.setState({ isTransactionLoading: false });
        this.setState({ transactionPaginationModel: result });
      } catch (_e) {
        this.setState({ isTransactionLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const TransactionConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
