/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import type { MemberPointModel, MemberTierModel } from '../../flow/flowTypes';
import { getPoint, getTier } from '../../controllers/memberController';
import LocalStorageService from '../../services/localstorageservice';

const localStorageService = LocalStorageService.getService();

const { Provider, Consumer } = React.createContext<any>();

type State = {
  getMemberPoint: Function,
  isMemberPointLoading: boolean,
  memberPoints: Array<MemberPointModel>,
  memberPoint: MemberPointModel,
  isMemberTierLoading: boolean,
  memberTier: MemberTierModel,
  getMemberTier: Function,
};

type Props = {
  children: Object,
};

export default class MemberProvider extends React.Component<Props, State> {
  state = {
    isMemberPointLoading: false,
    isMemberTierLoading: false,
    memberPoints: [],
    memberPoint: localStorageService.getPoint(),
    memberTier: localStorageService.getTier(),
    getMemberPoint: async () => {
      this.setState({ isMemberPointLoading: true });
      try {
        const result = await getPoint();
        this.setState({ isMemberPointLoading: false, memberPoints: result });
        if (result.length) {
          this.setState({ memberPoint: result[0] });
          localStorageService.setPoint(result[0]);
        } else {
          this.setState({ memberPoint: { point: 0 } });
          localStorageService.setPoint({ point: 0 });
        }
      } catch (_e) {
        this.setState({ isMemberPointLoading: false });
      }
    },
    getMemberTier: async () => {
      this.setState({ isMemberTierLoading: true });
      try {
        const result = await getTier();
        this.setState({ isMemberTierLoading: false, memberTier: result });
        localStorageService.setTier(result || "");
      } catch (_e) {
        this.setState({ isMemberTierLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const MemberConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
