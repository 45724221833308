/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import LocalStorageService from '../../services/localstorageservice';
import {
  viewProfile,
  editAddress,
  editCompany,
  addAddress,
} from '../../controllers/authController';
import type { CompanyModel, AddressModel } from '../../flow/flowTypes';

const localStorageService = LocalStorageService.getService();
const { Provider, Consumer } = React.createContext<any>();

type State = {
  authUser: Object,
  getProfile: Function,
  isLoading: boolean,
};

type Props = {
  children: Object,
};

export default class ProfileProvider extends React.Component<Props, State> {
  state = {
    authUser: localStorageService.getUser(),
    getProfile: async () => {
      this.setState({ isLoading: true });
      const { authUser } = this.state;
      const result = await viewProfile();
      this.setState({
        authUser: { ...authUser, ...result },
      });
      localStorageService.setUser({ ...authUser, ...result });

      this.setState({ isLoading: false });
    },
    editMyCompany: async (companyId: number, model: CompanyModel) => {
      this.setState({ isLoading: true });
      try {
        await editCompany(companyId, model);
        const user = localStorageService.getUser();
        this.setState({ isLoading: false, authUser: { ...user } });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
    editMyAddress: async (addressId: number, model: AddressModel) => {
      this.setState({ isLoading: true });
      const { authUser } = this.state;
      try {
        await editAddress(addressId, model);
        const user = localStorageService.getUser();
        this.setState({ isLoading: false, authUser: { ...authUser, ...user } });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
    addNewAddress: async (model: AddressModel) => {
      this.setState({ isLoading: true });
      const { authUser } = this.state;
      try {
        await addAddress(model);
        const user = localStorageService.getUser();
        this.setState({ isLoading: false, authUser: { ...authUser, ...user } });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const ProfileConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
