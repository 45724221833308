const themes = {
  default: {
    '--button-background-color': '#af8e56',
    '--positive-button-text-color': '#fff',
    '--bordered-button-text-color': '#b69764',
    '--bordered-button-border-color': '#b69764',
    '--loading-color': '#af8e56',
    '--header-nav-background': '#24293a',
    '--signout-text-color': '#fff',
    '--member-outer-container-gradient-front': '#FFF3E0',
    '--member-outer-container-gradient-back': '#D9D9D9',
    '--tier-outer-container-gradient-front': '#AF8E56',
    '--tier-outer-container-gradient-back': '#CE993F ',
    '--profile-back-text': '#af8e56',
    '--profile-save-text': '#fff',
    '--stepper-active-bg': '#af8e56',
    '--stepper-inactive-bg': '#bdb9b9',
  },
  surfacearchi: {
    '--button-background-color': '#af8e56',
    '--positive-button-text-color': '#fff',
    '--bordered-button-text-color': '#b69764',
    '--bordered-button-border-color': '#b69764',
    '--loading-color': '#af8e56',
    '--header-nav-background': '#24293a',
    '--signout-text-color': '#fff',
    '--member-outer-container-gradient-front': '#FFF3E0',
    '--member-outer-container-gradient-back': '#D9D9D9',
    '--tier-outer-container-gradient-front': '#AF8E56',
    '--tier-outer-container-gradient-back': '#CE993F ',
    '--profile-back-text': '#af8e56',
    '--profile-save-text': '#fff',
    '--stepper-active-bg': '#af8e56',
    '--stepper-inactive-bg': '#bdb9b9',
    '--point-history-bottom-underline': '#af8e56'
  },
};

export default themes;
